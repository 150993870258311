<template>
	<div>
		<div class="row">
			<div class="col-md-4">
				<b>Orden de servicio asociada</b>
				<a-input v-mask="'######'" v-model="service_order" :disabled="!isEditable" />
			</div>
			<div class="col-md-4">
				<b>Link de Trengo</b>
				<a-input autocomplete="off" v-model="trengo" :disabled="!isEditable" />
			</div>
			<div class="col-md-4">
				<b>Requerida en mostrador</b>
				<a-select style="width: 100%" v-model="localRequired">
					<a-select-option value="1">Si</a-select-option>
					<a-select-option value="0">No</a-select-option>
				</a-select>
			</div>
			<div class="col-12">
				<hr class="hrDotted" />
			</div>
		</div>
		<div v-if="isEditable">
			<div class="row pt5" v-for="(element, index) in elements" :key="index">
				<div class="col-12" v-if="index > 0">
					<hr class="hrDotted" />
				</div>
				<div class="col-md-1 text-center">
					<div style="font-size: 50px; text-align: center; height: 100px; vertical-align: middle; line-height: 130px">
						{{ index + 1 }}
					</div>
				</div>
				<div class="col-md-11">
					<div class="row">
						<div class="col-md-2">
							<b>Cantidad</b>
							<a-input v-if="element.measurement_unit == 'liter'" v-money="money" v-model="element.quantity" :disabled="!isEditable || !element.available" />
							<a-input v-else v-mask="'####'" v-model="element.quantity" :disabled="!isEditable || !element.available" />
						</div>
						<div class="col-md-3">
							<b>Unidad de medida</b>
							<a-select v-model="element.measurement_unit" :disabled="!isEditable || !element.available" style="width: 100%">
								<a-select-option value="liter">Litro</a-select-option>
								<a-select-option value="piece">Pieza</a-select-option>
								<a-select-option value="service">Servicio</a-select-option>
							</a-select>
						</div>
						<div class="col-md-7">
							<b>Descripción</b>
							<a-input v-model="element.description" :disabled="!isEditable || !element.available" />
						</div>
						<div class="col-md-4 pt10" v-if="!isNewRecord">
							<b>Precio</b>
							<a-input autocomplete="off" v-model="element.price" prefix="$" v-money="money" :disabled="!isEditable || !element.available" />
						</div>
						<div class="col-md-8 pt10">
							<b>Link de referencia</b>
							<a-input autocomplete="off" v-model="element.link" :disabled="!isEditable || !element.available" />
						</div>
						<div class="col-md-5 pt10" v-if="!isNewRecord">
							<b>Proveedor</b>
							<a-select show-search allow-clear option-filter-prop="children" :filter-option="filterOption" v-model="element.supplier_id" style="width: 100%">
								<a-select-option v-for="(supplier, index) in suppliersList" :key="index" :value="supplier.id">{{ supplier.tradename.toUpperCase() }}</a-select-option>
							</a-select>
							<small @click="getSuppliersList" class="pointer">Recarga</small>
						</div>
						<div class="col-md-7 pt10" v-if="!isNewRecord">
							<b>Tiempo de entrega</b>
							<a-input autocomplete="off" v-model="element.delivery_time" :disabled="!isEditable || !element.available" />
						</div>
						<div class="col-md-12 pt5" v-if="!element.available">
							<a-alert message="Producto agotado o no disponible" banner />
						</div>
					</div>
					<div class="row pt5" v-if="isEditable && !!element.available">
						<div class="col-md-6" v-if="!isNewRecord">
							<button class="btn btn-warning btn-sm" @click="unavailableElement(index)">Se encuentra agotado</button>
						</div>
						<div :class="!isNewRecord ? 'col-md-12' : 'col-md-6'" class="text-right" v-if="elements.length > 1">
							<button class="btn btn-danger btn-sm" @click="removeElement(index)">Eliminar partida</button>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="row" v-else>
			<div class="col-md-12">
				<a-table :columns="columns" :dataSource="elements" rowKey="id" :pagination="false">
					<div slot="index" slot-scope="record">
						{{ record.index }}
					</div>
					<div slot="quantity" slot-scope="record">
						<div class="text-left">{{ record.quantity }}x {{ record.measurement_unit == 'piece' ? 'pieza' : record.measurement_unit == 'liter' ? 'litro' : 'servicio' }}</div>
					</div>
					<div slot="description" slot-scope="record">
						<div class="text-left">
							{{ record.description }}
							<div v-if="record.link">
								<span class="pointer" @click="onLinkPress(record.link)"><a-icon type="login" /> Abrir link</span>
							</div>
							<a-alert message="Producto agotado o no disponible" banner v-if="!record.available" />
						</div>
					</div>
					<div slot="price" slot-scope="record">
						{{ record.available ? numeral(record.price).format('$0,0.00') : '---' }}
					</div>
					<div slot="importe" slot-scope="record">
						{{ record.available ? numeral(numeral(record.quantity).value() * numeral(record.price).value()).format('$0,0.00') : '---' }}
					</div>
					<div slot="delivery_time" slot-scope="record">
						{{ record.available ? record.delivery_time : '---' }}
					</div>
				</a-table>
			</div>
		</div>
		<div class="row pt20">
			<div class="col-12" v-if="elements.length == 1">
				<hr class="hrDotted" />
			</div>
			<div class="col-md-4 text-left">
				<a-button class="btn btn-warning" icon="arrow-left" @click="onCancel">Regresar</a-button>
			</div>
			<div class="col-md-8 text-right">
				<a-button icon="plus" type="primary" @click="addElement" v-if="isEditable">Añadir otro producto o servicio</a-button>
				<a-button icon="save" class="btn btn-success ml7" @click="onSave" v-if="isEditable">Guardar</a-button>
				<a-button icon="file" class="btn btn-warning ml7" @click="onCloseQuotation" v-if="!isNewRecord && isEditable && allowCloseQuotation">Cerrar cotización</a-button>
				<a-button icon="file" class="btn btn-info" @click="getQuotation" v-if="actualImpressment.status > 0">Visualizar cotización</a-button>
			</div>
		</div>
		<a-modal :visible="modalQuotationView" title="Detalle de cotización" :closable="false" width="80%" v-if="modalQuotationView">
			<template slot="footer">
				<a-button key="back" @click="closeQuotationModal"> Cerrar </a-button>
			</template>
			<div class="col-md-12 pt10">
				<object :data="quotationBase64" type="application/pdf" width="100%" height="600px"></object>
			</div>
		</a-modal>
	</div>
</template>
<script>
import { mapGetters } from 'vuex'
import _ from 'lodash'
import utilities from '@/services/utilities'
import { impressment } from '@/constants'
import { mask } from 'vue-the-mask'
import { VMoney } from 'v-money'
import Swal from 'sweetalert2'
import numeral from 'numeral'

export default {
	name: 'insertOrUpdateImpressment',
	directives: {
		mask,
		money: VMoney,
	},
	computed: {
		...mapGetters('impressment', ['actualImpressment']),
		...mapGetters('serviceOrders', ['serviceOrdersList']),
		...mapGetters('suppliers', ['suppliersList']),
		isNewRecord() {
			return !!!utilities.objectValidate(this.$route, 'params.id', false)
		},
		serviceOrderLoader() {
			return this.$store.state.serviceOrders.spinnerLoader
		},
		isEditable() {
			return this.isNewRecord || this.actualImpressment.status == 0
		},
		allowCloseQuotation() {
			return !this.elements.filter((e) => e.available && numeral(e.price).value() == 0).length
		},
	},
	data() {
		return {
			elements: [],
			service_order: '',
			trengo: '',
			localRequired: '',
			money: {
				decimal: '.',
				thousands: ',',
				precision: 2,
			},
			columns: [
				{
					title: '#',
					align: 'center',
					scopedSlots: { customRender: 'index' },
				},
				{
					title: 'Cantidad',
					align: 'center',
					scopedSlots: { customRender: 'quantity' },
				},
				{
					title: 'Descripción',
					align: 'center',
					scopedSlots: { customRender: 'description' },
				},
				{
					title: 'Precio unitario',
					align: 'center',
					scopedSlots: { customRender: 'price' },
				},
				{
					title: 'Importe',
					align: 'center',
					scopedSlots: { customRender: 'importe' },
				},
				{
					title: 'Tiempo de entrega',
					align: 'center',
					scopedSlots: { customRender: 'delivery_time' },
				},
			],
			modalQuotationView: false,
		}
	},
	beforeMount() {
		this.getSuppliersList()
	},
	mounted() {
		if (this.isNewRecord) {
			this.elements.push(_.cloneDeep(impressment))
		} else {
			if (utilities.objectValidate(this.actualImpressment, 'elements', false)) {
				this.elements = _.cloneDeep(this.actualImpressment.elements)
			} else {
				this.$store.dispatch('impressment/GET_ONE', this.$route.params.id)
			}
		}
	},
	methods: {
		numeral,
		getSuppliersList() {
			this.$store.dispatch('suppliers/GET_NO_REDIRECT')
		},
		filterOption(input, option) {
			return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
		},
		addElement() {
			let errors = []
			this.elements.forEach((element, index) => {
				if (!element.quantity) {
					errors.push(`- El elemento #${index + 1} no cuenta con cantidad.`)
				}
				if (element.description.length < 5) {
					errors.push(`- El elemento #${index + 1} no cuenta con descripción de al menos 5 caracteres.`)
				}
				if (element.measurement_unit == '') {
					errors.push(`- El elemento #${index + 1} no cuenta con unidad de medida.`)
				}
			})

			if (errors.length) {
				Swal.fire({
					title: 'Atención',
					html: errors.join('<br>'),
					icon: 'warning',
					confirmButtonText: 'Ok',
				})
				return false
			}
			this.elements.push(_.cloneDeep(impressment))
		},
		unavailableElement(index) {
			Swal.fire({
				title: 'Atención',
				html: `Indicarás que el producto no se encuentra disponible. ¿Deseas continuar?`,
				icon: 'warning',
				showCancelButton: true,
				reverseButtons: true,
				confirmButtonColor: '#41b883',
				confirmButtonText: 'Sí, continuar',
				cancelButtonColor: '#f5222e',
				cancelButtonText: 'No, cancelar',
			}).then((result) => {
				if (result.isConfirmed) {
					this.elements[index].available = false
				}
			})
		},
		removeElement(index) {
			Swal.fire({
				title: 'Atención',
				html: `¿Deseas eliminar el elemento <b>"${this.elements[index].description}"</b> de ODR?`,
				icon: 'warning',
				showCancelButton: true,
				reverseButtons: true,
				confirmButtonColor: '#41b883',
				confirmButtonText: 'Sí, continuar',
				cancelButtonColor: '#f5222e',
				cancelButtonText: 'No, cancelar',
			}).then((result) => {
				if (result.isConfirmed) {
					let allElements = _.cloneDeep(this.elements)
					this.elements = []
					allElements.splice(index, 1)
					this.elements = _.cloneDeep(allElements)
				}
			})
		},
		async onSave() {
			let errors = []
			this.elements.forEach((element, index) => {
				let item = index + 1
				if (element.quantity == '') {
					errors.push(`- Se requiere cantidad en la partida #${item}`)
				}
				if (element.measurement_unit == '') {
					errors.push(`- Se requiere unidad de medida en la partida #${item}`)
				}
				if (element.description == '') {
					errors.push(`- Se requiere descripción en la partida #${item}`)
				}
				//
				if (this.actualImpressment.status == 1 && numeral(element.price).value() == 0) {
					errors.push(`- Se requiere precio en la partida #${item}`)
				}
			})

			if (this.localRequired == '') {
				errors.push(`¿La cotización fue requerida en mostrador?`)
			}

			if (errors.length) {
				Swal.fire({
					title: 'Cotizaciones',
					html: errors.join('<br>'),
					icon: 'error',
					confirmButtonText: 'Ok',
				})
				return false
			}

			let payload = {
				service_order: Number(this.service_order),
				elements: this.elements,
				trengo: this.trengo,
				local_required: this.localRequired == '1' ? true : false,
			}

			if (this.isNewRecord) {
				await this.$store
					.dispatch('impressment/CREATE', payload)
					.then(async (response) => {
						await Swal.fire({
							title: 'Cotizaciones',
							text: utilities.objectValidate(response, 'message', 'Registro generado con éxito.'),
							icon: 'success',
							confirmButtonText: 'Ok',
						})
					})
					.catch(async (error) => {
						await Swal.fire({
							title: 'Cotizaciones',
							text: utilities.objectValidate(error, 'response.data.message', 'Error al generar el registro. Intenta de nuevo.'),
							icon: 'error',
							confirmButtonText: 'Ok',
						})
					})
			} else {
				payload = {
					...payload,
					id: this.$route.params.id,
				}
				await this.$store
					.dispatch('impressment/UPDATE', payload)
					.then(async (response) => {
						await Swal.fire({
							title: 'Cotizaciones',
							text: utilities.objectValidate(response, 'message', 'Registro actualizado con éxito.'),
							icon: 'success',
							confirmButtonText: 'Ok',
						})
					})
					.catch(async (error) => {
						await Swal.fire({
							title: 'Cotizaciones',
							text: utilities.objectValidate(error, 'response.data.message', 'Error al actualizar el registro. Intenta de nuevo.'),
							icon: 'error',
							confirmButtonText: 'Ok',
						})
					})
			}
		},
		onCancel() {
			this.$router.replace('/impressment')
		},
		onCloseQuotation() {
			Swal.fire({
				title: 'Atención',
				text: '¿Deseas cerrar la cotización?',
				icon: 'warning',
				showCancelButton: true,
				reverseButtons: true,
				confirmButtonColor: '#41b883',
				confirmButtonText: 'Sí, continuar',
				cancelButtonColor: '#f5222e',
				cancelButtonText: 'No, cancelar',
			}).then(async (result) => {
				if (result.isConfirmed) {
					// Guardamos la información actual
					await this.onSave()

					await this.$store
						.dispatch('impressment/UPDATE_STATUS', {
							id: this.$route.params.id,
							status: 1, // Cotizadas
						})
						.then(async (response) => {
							await Swal.fire({
								title: 'Cotizaciones',
								text: utilities.objectValidate(response, 'message', 'Registro actualizado con éxito.'),
								icon: 'success',
								confirmButtonText: 'Ok',
							})
						})
						.catch(async (error) => {
							await Swal.fire({
								title: 'Cotizaciones',
								text: utilities.objectValidate(error, 'response.data.message', 'Error al actualizar el registro. Intenta de nuevo.'),
								icon: 'error',
								confirmButtonText: 'Ok',
							})
						})

					// console.log('ENVIAR PDF', { trengo: this.trengo })
					if (this.trengo != '') {
						Swal.fire({
							title: 'Atención',
							text: '¿Deseas enviar la cotización al cliente?',
							icon: 'warning',
							showCancelButton: true,
							reverseButtons: true,
							confirmButtonColor: '#41b883',
							confirmButtonText: 'Sí, continuar',
							cancelButtonColor: '#f5222e',
							cancelButtonText: 'No, cancelar',
						}).then(async (resultNotification) => {
							if (resultNotification.isConfirmed) {
								//enviamos cotización por trengo
								if (utilities.objectValidate(this.actualImpressment, 'trengo', false)) {
									let payload = {
										id: this.actualImpressment.id,
										ticket: this.actualImpressment.trengo.toLowerCase().split('tickets/')[1],
									}
									await this.$store.dispatch('impressment/SEND_GENERATE_PDF', payload)
								}
							}
						})
					}
				}
			})
		},
		getQuotation() {
			this.$store.dispatch('impressment/GENERATE_PDF', this.$route.params.id).then((response) => {
				this.quotationBase64 = `data:application/pdf;base64,${response.data.base64}`
				setTimeout(() => {
					this.modalQuotationView = true
				}, 100)
			})
		},
		closeQuotationModal() {
			this.modalQuotationView = false
			this.quotationBase64 = ''
		},
	},
	watch: {
		actualImpressment(newData) {
			if (utilities.objectValidate(newData, 'impressmentElements', false)) {
				this.service_order = utilities.objectValidate(newData, 'service_order', '').toString()
				this.localRequired = utilities.objectValidate(newData, 'local_required', '').toString()
				this.trengo = utilities.objectValidate(newData, 'trengo', '').toLowerCase()
				this.elements = newData.impressmentElements.map((e, index) => {
					return {
						...impressment,
						...e,
						price: numeral(e.price).format('0,0.00'),
						index: index + 1,
					}
				})
			}
		},
	},
}
</script>
<style lang="scss" module>
@import './style.module.scss';
</style>